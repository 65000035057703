import React from "react";
import { graphql } from 'gatsby';
import ContactUs from '../../components/Contact';

const Contact = () => <ContactUs />;

export default Contact;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;